import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import store from './store'
import vuetify from './plugins/vuetify'
import VueMeta from "vue-meta"
import router from './router'

Vue.config.productionTip = false

Vue.use(VueMeta)

Vue.filter('capitalize', (value) => value.charAt(0).toUpperCase() + value.slice(1))

Vue.filter('byArea', (items, areas) => {
  return items.filter(i =>
    !areas.length
    || areas.some(a => a.area === i.area)
  )
});

new Vue({
  store,
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
