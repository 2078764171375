export default {
    icons: [
        {id: 0, img: require('@/assets/items/nut.webp')},
        {id: 1, img: require('@/assets/items/stick.webp')},
        {id: 2, img: require('@/assets/items/bomb.webp')},
        {id: 3, img: require('@/assets/items/bow.webp')},
        {id: 4, img: require('@/assets/items/arrow_fire.webp')},
        {id: 5, img: require('@/assets/items/din.webp')},
        {id: 6, img: require('@/assets/items/slingshot.webp')},
        {id: 7, img: require('@/assets/items/ocarina_fairy.webp')},
        {id: 8, img: require('@/assets/items/ocarina_time.webp')},
        {id: 9, img: require('@/assets/items/bombchu.webp')},
        {id: 10, img: require('@/assets/items/hookshot.webp')},
        {id: 11, img: require('@/assets/items/longshot.webp')},
        {id: 12, img: require('@/assets/items/arrow_ice.webp')},
        {id: 13, img: require('@/assets/items/farore.webp')},
        {id: 14, img: require('@/assets/items/boomerang.webp')},
        {id: 15, img: require('@/assets/items/lense.webp')},
        {id: 16, img: require('@/assets/items/bean.webp')},
        {id: 17, img: require('@/assets/items/hammer.webp')},
        {id: 18, img: require('@/assets/items/arrow_light.webp')},
        {id: 19, img: require('@/assets/items/nayru.webp')},
        {id: 20, img: require('@/assets/items/bottle.webp')},
        {id: 21, img: require('@/assets/items/bottle_red_potion.webp')},
        {id: 22, img: require('@/assets/items/bottle_green_potion.webp')},
        {id: 23, img: require('@/assets/items/bottle_blue_potion.webp')},
        {id: 24, img: require('@/assets/items/bottle_fairy.webp')},
        {id: 25, img: require('@/assets/items/bottle_fish.webp')},
        {id: 26, img: require('@/assets/items/bottle_milk.webp')},
        {id: 27, img: require('@/assets/items/bottle_letter.webp')},
        {id: 28, img: require('@/assets/items/bottle_blue_fire.webp')},
        {id: 29, img: require('@/assets/items/bottle_bug.webp')},
        {id: 30, img: require('@/assets/items/bottle_big_poe.webp')},
        {id: 31, img: require('@/assets/items/bottle_milk_half.webp')},
        {id: 32, img: require('@/assets/items/bottle_poe.webp')},
        {id: 33, img: require('@/assets/items/letter.webp')},
        {id: 34, img: require('@/assets/items/mask_keaton.webp')},
        {id: 35, img: require('@/assets/items/mask_skull.webp')},
        {id: 36, img: require('@/assets/items/mask_spooky.webp')},
        {id: 37, img: require('@/assets/items/mask_bunny.webp')},
        {id: 38, img: require('@/assets/items/mask_goron.webp')},
        {id: 39, img: require('@/assets/items/mask_zora.webp')},
        {id: 40, img: require('@/assets/items/mask_gerudo.webp')},
        {id: 41, img: require('@/assets/items/mask_truth.webp')},
        {id: 42, img: require('@/assets/items/sold.webp')},
        {id: 43, img: require('@/assets/items/pocket_egg.webp')},
        {id: 44, img: require('@/assets/items/pocket_cucco.webp')},
        {id: 45, img: require('@/assets/items/cojiro.webp')},
        {id: 46, img: require('@/assets/items/mushroom.webp')},
        {id: 47, img: require('@/assets/items/odd_potion.webp')},
        {id: 48, img: require('@/assets/items/saw.webp')},
        {id: 49, img: require('@/assets/items/sword_broken_biggoron.webp')},
        {id: 50, img: require('@/assets/items/prescription.webp')},
        {id: 51, img: require('@/assets/items/frog.webp')},
        {id: 52, img: require('@/assets/items/eye_drops.webp')},
        {id: 53, img: require('@/assets/items/claim_check.webp')},
        {id: 54, img: require('@/assets/items/sword_kokiri.webp')},
        {id: 55, img: require('@/assets/items/sword_master.webp')},
        {id: 56, img: require('@/assets/items/sword_biggoron.webp')},
        {id: 57, img: require('@/assets/items/shield_deku.webp')},
        {id: 58, img: require('@/assets/items/shield_hylian.webp')},
        {id: 59, img: require('@/assets/items/shield_mirror.webp')},
        {id: 60, img: require('@/assets/items/tunic_kokiri.webp')},
        {id: 61, img: require('@/assets/items/tunic_goron.webp')},
        {id: 62, img: require('@/assets/items/tunic_zora.webp')},
        {id: 63, img: require('@/assets/items/boots.webp')},
        {id: 64, img: require('@/assets/items/boots_iron.webp')},
        {id: 65, img: require('@/assets/items/boots_hover.webp')},
        {id: 66, img: require('@/assets/items/bullet_bag.webp')},
        {id: 67, img: require('@/assets/items/bullet_bag_large.webp')},
        {id: 68, img: require('@/assets/items/bullet_bag_giant.webp')},
        {id: 69, img: require('@/assets/items/quiver.webp')},
        {id: 70, img: require('@/assets/items/quiver_large.webp')},
        {id: 71, img: require('@/assets/items/quiver_giant.webp')},
        {id: 72, img: require('@/assets/items/gauntlet_silver.webp')},
        {id: 73, img: require('@/assets/items/gauntlet_gold.webp')},
        {id: 74, img: require('@/assets/items/scale_silver.webp')},
        {id: 75, img: require('@/assets/items/scale_gold.webp')},
        {id: 76, img: require('@/assets/items/sword_broken_giantknive.webp')},
        {id: 77, img: require('@/assets/items/wallet_adult.webp')},
        {id: 78, img: require('@/assets/items/wallet_giant.webp')},
        {id: 79, img: require('@/assets/items/seeds.webp')},
        {id: 80, img: require('@/assets/items/fishing_rod.webp')},
        {id: 81, img: require('@/assets/items/medallion_forest.webp')},
        {id: 82, img: require('@/assets/items/medallion_fire.webp')},
        {id: 83, img: require('@/assets/items/medallion_water.webp')},
        {id: 84, img: require('@/assets/items/medallion_spirit.webp')},
        {id: 85, img: require('@/assets/items/medallion_shadow.webp')},
        {id: 86, img: require('@/assets/items/medallion_light.webp')},
        {id: 87, img: require('@/assets/items/stone_kokiri.webp')},
        {id: 88, img: require('@/assets/items/stone_goron.webp')},
        {id: 89, img: require('@/assets/items/stone_zora.webp')},
        {id: 90, img: require('@/assets/items/agony.webp')},
        {id: 91, img: require('@/assets/items/gerudo_pass.webp')},
        {id: 92, img: require('@/assets/items/skulltulla.webp')},
        {id: 93, img: require('@/assets/items/heart_container.webp')},
        {id: 94, img: require('@/assets/items/key_master.webp')},
        {id: 95, img: require('@/assets/items/compass.webp')},
        {id: 96, img: require('@/assets/items/map.webp')},
        {id: 97, img: require('@/assets/items/key.webp')},
        {id: 98, img: require('@/assets/items/magic.webp')},
        {id: 99, img: require('@/assets/items/magic_full.webp')},
    ],
    getIcon(id) {
        return this.icons.find(i => i.id === id);
    },
}