import Parser from "@/helper/parser";
import Vue from "vue";
import router from "@/router";

export default {
    namespaced: true,
    state: {
        log: {},
        history: [],
    },
    getters: {
        id: (state) => {
            return state.log.id;
        },
        logFile: (state) => {
            return {
                id: state.log.id,
                file: state.log.file,
            };
        },

        locations: (state) => {
            return state.log.locations || [];
        },
        hints: (state) => {
            return state.log.hints || [];
        },
        playthrough: (state) => {
            return state.log.playthrough || [];
        },
        entrances: (state) => {
            return state.log.entrances || [];
        },
        tricks: (state) => {
            return state.log.tricks || [];
        },
        settings: (state) => {
            return state.log.settings || [];
        },

        history: (state) => {
            return state.history || [];
        },
    },
    mutations: {
        setLocationDone: (state, {location, done}) => {
            state.log.locations.find(l => l === location).done = done;
        },
        setHintDone: (state, {hint, done}) => {
            state.log.hints.find(h => h === hint).done = done;
        },

        load: (state, log) => {
            state.history = state.history
                .filter(h => h.id !== log.id)
                .concat(log)
                .sort((a, b) => a.id.localeCompare(b.id));

            state.log = log;
            router.push('/').catch(() => {});
        },
        deleteHistory: (state, log) => {
            state.history = state.history.filter(h => h.id !== log.id);
        },
        reset: (state) => {
            state.log.locations.map(l => l.done = false);
            state.log.hints.map(l => l.done = false);
        },
        clear: (state) => {
            state.log = {};
            router.push('/').catch(() => {});
        },
    },
    actions: {
        load: (context, file) => {
            context.dispatch('Settings/reset', null, { root: true });

            return Parser.parse(file).then((log) => context.commit('load', log));
        },
        deleteHistory: (context, log) => {
            context.commit('deleteHistory', log);

            if (log.id === context.state.log.id) context.commit('clear');
        },
        shareLog: (context) => {
            return Vue.axios.post('', context.getters['logFile'].file)
                .then((response) => {
                    return router.push('/' + response).catch(() => {});
                });
        },
        loadFromServer: (context, id) => {
            return Vue.axios.get(id)
                .then((response) => {
                    return context.dispatch('load', response);
                })
                .then(() => {
                    return router.replace('/' + id).catch(() => {});
                })
                .catch(() => context.commit('clear'));
        },
        reset: (context) => {
            context.dispatch('Settings/reset', null, { root: true });

            return context.commit('reset');
        }
    },
}