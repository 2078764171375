<template>
    <v-select
        solo
        flat
        hide-details
        append-icon="mdi-chevron-down"
        label="Seed History"
        :items="history"
        item-text="id"
        return-object
        :value="id"
        @change="loadHistory($event)"
        :class="{ transparent: transparent }"
    >
        <template v-slot:selection="{ item }">
            <div
                v-for="icon in item.hash"
                :key="item.id + icon"
            >
                <spoiled-item-icon :id="icon"/>
            </div>
        </template>

        <template v-slot:item="{ item }">
          <v-tooltip bottom :open-delay="500">
            <template v-slot:activator="{ on }">
              <v-layout
                  d-flex
                  v-on="on"
              >
                <div
                    v-for="icon in item.hash"
                    :key="item.id + icon"
                >
                  <spoiled-item-icon :id="icon"/>
                </div>
              </v-layout>
            </template>

            {{ item.id + '.json' }}
          </v-tooltip>

            <v-spacer class="mx-3"/>

            <div class="subtext mr-5 text-no-wrap nocopy">{{ item.version }}</div>
            <v-btn icon @click="deleteHistory(item)">
                <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
        </template>
    </v-select>
</template>

<script>
import SpoiledItemIcon from "@/components/base/SpoiledItemIcon.vue";

export default {
    name: "SpoiledHistory",
  components: {SpoiledItemIcon},
    props: {
        transparent: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        id() {
            return this.$store.getters['Log/id'];
        },
        history() {
            return this.$store.getters['Log/history'];
        },
    },
    methods: {
        loadHistory(log) {
            this.$store.commit('Log/load', log);
        },
        deleteHistory(log) {
            this.$store.dispatch('Log/deleteHistory', log);
        },
    },
}
</script>

<style scoped>
/deep/ input {
    display: none;
}

.transparent /deep/ .v-input__slot {
    background: transparent !important;
}
</style>