<template>
    <v-layout d-flex column>
        <v-layout d-flex shrink class="mb-3">
            <spoiled-route-selector ref="routeSelector" @route="startRoute($event)"/>
        </v-layout>

        <v-layout d-flex shrink class="mb-3">
            <v-row>
                <v-col>
                    <v-autocomplete
                        solo
                        hide-details
                        clearable
                        placeholder="Search"
                        prepend-inner-icon="mdi-magnify"
                        :items="entrances"
                        item-text="label"
                        item-value="id"
                        @input="search"
                        v-model="searchValue"
                    />
                </v-col>
                <v-col>
                    <spoiled-playthrough-select :value="selectedPlaythrough" @input="selectPlaythrough($event)"/>
                </v-col>
            </v-row>
        </v-layout>

        <v-layout d-flex grow>
            <v-row no-gutters>
                <v-col>
                    <spoiled-map ref="map" :value="graph" class="fill-height"/>
                </v-col>
                <v-col lg="3" v-if="route.length">
                    <spoiled-route :value="route" class="fill-height" @select="focus($event)"/>
                </v-col>
            </v-row>
        </v-layout>
    </v-layout>
</template>

<script>
import Entrances from "@/helper/entrances";
import SpoiledMap from "@/components/base/SpoiledMap.vue";
import SpoiledRoute from "@/components/base/SpoiledRoute.vue";
import SpoiledRouteSelector from "@/components/base/SpoiledRouteSelector.vue";
import areas from "@/helper/areas";
import SpoiledPlaythroughSelect from "@/components/base/SpoiledPlaythroughSelect.vue";

export default {
    name: "SpoiledNavigation",
    components: {SpoiledPlaythroughSelect, SpoiledRouteSelector, SpoiledRoute, SpoiledMap},
    data: function() {
        return {
            route: [],
            searchValue: null,
            selectedPlaythrough: null,
        };
    },
    computed: {
        graph() {
            return Entrances.graph(this.$store.getters['Log/entrances'].map(e => Entrances.translate(e)));
        },
        entrances() {
            return [...Entrances.entrances].sort((a, b) => a.label.localeCompare(b.label));
        },
    },
    methods: {
        startRoute(routeParams) {
            this.route = this.$refs.map.shortestPath(routeParams.start, routeParams.end);
        },
        search() {
            this.$refs.map.focus(this.searchValue);
        },
        focus(value) {
            this.searchValue = null;
            this.$refs.map.focus(value);
        },
        selectPlaythrough(step) {
            this.searchValue = null;
            this.selectedPlaythrough = step;

            let nodes = [];

            if (step !== null) {
                nodes = this.$store.getters['Log/playthrough'][step].steps
                    .map(l => areas.find(a => a.locations.includes(l))?.area
                        .toLowerCase()
                        .replaceAll('\'', '')
                        .replaceAll(' ', '-'))
                    .filter(l => !!l);
            }

            this.$refs.map.select(nodes);
        },
    },
    watch: {
        graph() {
            this.$refs.routeSelector.reset();
        },
        '$store.state.Log.log.id'() {
            this.searchValue = null;
            this.search();
        },
    },
}
</script>

<style scoped>

</style>