<template>
    <div>
        <spoiled-area-filter v-model="areas" class="mb-5"/>

        <v-row>
            <v-col cols="12" sm="6" v-for="(hint, key) in altarTexts" :key="key">
                <v-layout d-flex fill-height>
                    <spoiled-hint :value="hint" centered/>
                </v-layout>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" sm="6" md="3" lg="2" v-for="(hint, key) in specialTexts" :key="key">
                <v-layout d-flex fill-height>
                    <spoiled-hint :value="hint"/>
                </v-layout>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" sm="6" md="3" lg="2" v-for="(hint, key) in songTexts" :key="key">
                <v-layout d-flex fill-height>
                    <spoiled-hint :value="hint"/>
                </v-layout>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" sm="6" md="3" lg="2" v-for="(hint, key) in gossipStones" :key="key">
                <v-layout d-flex fill-height>
                    <spoiled-hint :value="hint"/>
                </v-layout>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import SpoiledHint from "@/components/base/SpoiledHint";
import SpoiledAreaFilter from "@/components/base/SpoiledAreaFilter";

export default {
    name: "SpoiledHints",
    components: {SpoiledAreaFilter, SpoiledHint},
    data: function () {
        return {
            replacements: [
                {from: '$0', to: '<img height="12" src="' + require('@/assets/items/stone_kokiri.webp') + '"/></div> '},
                {from: '$1', to: '<img height="12" src="' + require('@/assets/items/stone_goron.webp') + '"/> '},
                {from: '$2', to: '<img height="12" src="' + require('@/assets/items/stone_zora.webp') + '"/> '},
                {from: '$3', to: '<img height="12" src="' + require('@/assets/items/medallion_forest.webp') + '"/> '},
                {from: '$4', to: '<img height="12" src="' + require('@/assets/items/medallion_fire.webp') + '"/> '},
                {from: '$5', to: '<img height="12" src="' + require('@/assets/items/medallion_water.webp') + '"/> '},
                {from: '$6', to: '<img height="12" src="' + require('@/assets/items/medallion_spirit.webp') + '"/> '},
                {from: '$7', to: '<img height="12" src="' + require('@/assets/items/medallion_shadow.webp') + '"/> '},
                {from: '$8', to: '<img height="12" src="' + require('@/assets/items/medallion_light.webp') + '"/> '},
                {from: '$i', to: ''},
                {from: '$c', to: ''},
                {from: '$o', to: ''},
                {from: '$k', to: ''},
                {from: '$b', to: ''},
                {from: '$l', to: ''},
                {from: '%g', to: ''},
                {from: '%r', to: ''},
                {from: '%w', to: ''},
                {from: '%y', to: ''},
                {from: '%c', to: ''},
                {from: '%p', to: ''},
                {from: '$C', to: ''},
                {from: '^', to: '<br>'},
                {from: '&', to: '<br>'},
                {from: '@', to: 'Link'},
            ],
            areas: [],
        };
    },
    computed: {
        altarTexts() {
            return this.$options.filters.byArea(
                this.replace(this.$store.getters['Log/hints'].filter(h => h.type === 'altar')),
                this.areas
            );
        },
        specialTexts() {
            return this.$options.filters.byArea(
                this.replace(this.$store.getters['Log/hints'].filter(h => h.type === 'special')),
                this.areas
            );
        },
        songTexts() {
            return this.$options.filters.byArea(
                this.replace(this.$store.getters['Log/hints'].filter(h => h.type === 'song')),
                this.areas
            );
        },
        gossipStones() {
            return this.$options.filters.byArea(
                this.replace(this.$store.getters['Log/hints'].filter(h => h.type === 'gossip')),
                this.areas
            );
        },
    },
    methods: {
        replace(texts) {
            return texts
                .map(h => {
                    this.replacements.forEach(r => {
                        h.hint = h.hint.replaceAll(r.from, r.to);
                    })
                    return h;
                })
        },
    },
}
</script>

<style scoped>

</style>