<template>
    <v-app-bar app dense>
        <v-app-bar-nav-icon disabled class="ml-3">
            <spoiled-logo :size="24"/>
        </v-app-bar-nav-icon>

        <v-layout d-flex align-baseline class="nocopy">
            <div class="triforce mr-1">{{ appName }}</div>
            <div class="subtext text--secondary">{{ appVersion }}</div>
        </v-layout>

        <slot/>

        <v-layout d-flex grow class="px-3 primary darken-1">
            <spoiled-history transparent class="flex-shrink-0"/>

            <spoiled-tricks-button/>
            <spoiled-new-button/>
            <spoiled-reset-button/>
            <spoiled-share-button/>
            <spoiled-download-button/>
        </v-layout>
    </v-app-bar>
</template>

<script>
import appInfo from "@/helper/appInfo";
import SpoiledHistory from "@/components/base/SpoiledHistory";
import SpoiledLogo from "@/components/base/SpoiledLogo";
import SpoiledNewButton from "@/components/base/buttons/SpoiledNewButton";
import SpoiledResetButton from "@/components/base/buttons/SpoiledResetButton";
import SpoiledShareButton from "@/components/base/buttons/SpoiledShareButton";
import SpoiledDownloadButton from "@/components/base/buttons/SpoiledDownloadButton";
import SpoiledTricksButton from "@/components/base/buttons/SpoiledTricksButton.vue";

export default {
    name: "SpoiledBar",
    components: {
        SpoiledTricksButton,
        SpoiledDownloadButton,
        SpoiledShareButton, SpoiledResetButton, SpoiledNewButton, SpoiledLogo, SpoiledHistory},
    computed: {
        appName() {
            return appInfo.name;
        },
        appVersion() {
            return appInfo.version;
        },
    },
}
</script>

<style scoped>
/deep/ .v-toolbar__content {
    padding: 4px 0;
}
</style>