<script>
import randomizer from "@/helper/randomizer";

export default {
  name: "SpoiledItemIcon",
  computed: {
    randomizer() {
      return randomizer
    }
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
}
</script>

<template>
  <v-img :src="randomizer.getIcon(id).img" width="24" height="24" contain/>
</template>

<style scoped>

</style>