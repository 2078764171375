<template>
    <v-card
        width="100%"
        class="nocopy"
    >
        <v-toolbar
            dense
            flat
            class="interactable"
            @click="setDone"
            :class="{ 'green darken-3': value.done }"
        >
            <b>{{ value.id }}</b>
        </v-toolbar>

        <div
            class="px-4 py-3 text--secondary"
            :class="{ 'no-spoilers': !showSpoilers, 'text-center': centered }"
            v-html="value.hint"
        />
    </v-card>
</template>

<script>
export default {
    name: "SpoiledHint",
    props: {
        value: {
            type: Object,
            required: true,
        },
        centered: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        showSpoilers() {
            return this.$store.getters['Settings/showSpoilers']
                || this.$store.getters['Settings/showFound'] && this.value.done;
        },
    },
    methods: {
        setDone() {
            this.$store.commit('Log/setHintDone', {
                hint: this.value,
                done: !this.value.done,
            });
        }
    }
}
</script>

<style scoped>

</style>