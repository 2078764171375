import areas from "@/helper/areas";
import prices from "@/helper/prices";

export default {
    parse(data) {
        return {
            id: this.parseId(data),
            hash: this.parseHash(data),
            version: this.parseVersion(data),
            locations: this.parseLocations(data),
            hints: this.parseHints(data),
            playthrough: this.parsePlaythrough(data),
            entrances: this.parseEntrances(data),
            settings: this.parseSettings(data),
            file: data,
        };
    },

    isValid: (data) => {
        return data['file_hash']
            && data['version']
            && data['locations']
            && data['hints']
            && data['playthrough']
            && data['settings'];
    },

    parseId: (data) => {
        return data['file_hash'].join('-');
    },
    parseHash: (data) => {
        return data['file_hash'];
    },
    parseVersion: (data) => {
        return data['version'];
    },
    parseLocations: (data) => {
        return Object.entries(data.locations).map((e) => {
            let entry = {
                id: e[0],
                item: e[1],
                done: false,
                trap: false,
                price: 0,
                area: areas.find(a => a.locations.includes(e[0]))?.area,
            };

            if (typeof e[1] === 'object') {
                entry.item = e[1].item;

                if (entry.item === 'Ice Trap') {
                    entry.item = e[1].model;
                    entry.trap = true;
                }

                entry.price = e[1].price ?? 0;
            }

            if (!entry.price && prices.isShop(entry.id)) {
                let item = prices.find(entry.item);
                entry.item = item.name;
                entry.price = item.price;
            }

            if (e[0] === 'Wasteland Carpet Salesman') entry.price = 200;

            return entry;
        });
    },
    parseHints: (data) => {
        return [
            {id: 'ToT Child Altar', hint: data.childAltar.hintText, type: 'altar'},
            {id: 'ToT Adult Altar', hint: data.adultAltar.hintText, type: 'altar'},
            {id: 'Ganondorf', hint: data.ganonHintText, type: 'special'},
            {id: 'MK Chest Game Owner', hint: data.gregText, type: 'special'},
            {id: 'GY Gravekeepers Hut', hint: data.dampeText, type: 'special'},
            {id: 'Minuet of Forest', hint: data.warpMinuetText, type: 'song'},
            {id: 'Bolero of Fire', hint: data.warpBoleroText, type: 'song'},
            {id: 'Serenade of Water', hint: data.warpSerenadeText, type: 'song'},
            {id: 'Nocturne of Shadows', hint: data.warpNocturneText, type: 'song'},
            {id: 'Requiem of Spirits', hint: data.warpRequiemText, type: 'song'},
            {id: 'Prelude of Light', hint: data.warpPreludeText, type: 'song'},
            ...Object.entries(data.hints).map(h => {
                return {
                    id: h[0],
                    hint: h[1].hint,
                    type: 'gossip'
                };
            }),
        ]
            .filter(h => !!h.hint)
            .map(h => {
                h.area = areas.find(a => a.locations.includes(h.id))?.area;
                h.done = false;

                return h;
            });
    },
    parsePlaythrough: (data) => {
        return Object.entries(data.playthrough).map(s => {
            return {
                name: s[0],
                steps: Object.keys(s[1]),
            };
        });
    },
    parseEntrances: (data) => {
        if (!data.entrances) return [];

        return data.entrances.map(e => {
            return {
                from: e.index,
                to: e.override,
            };
        })
            .filter(e => e.from !== e.to);
    },
    parseSettings: (data) => {
        let settings = [];

        Object.entries(data.settings).map(s => {
            let option = s[0].split(':');

            let category = 'Gameplay Settings'
            let name = option[0];
            let value = s[1];

            if (option.length !== 1) {
                category = option[0]
                name = option[1];
            }

            settings.push({
                category,
                name,
                value,
            });
        })

        return settings;
    },
}