<script>
import {defineComponent} from 'vue'

export default defineComponent({
    name: "SpoiledPlaythroughSelect",
    props: {
        value: {},
    },
    computed: {
        playthroughSteps() {
            return Object.keys(this.$store.getters['Log/playthrough'])
                .map(s => {
                    return {
                        text: 'Sphere ' + s,
                        value: parseInt(s),
                    };
                });
        },
    },
    methods: {
        select(value) {
            this.$emit('input', value);
        },
    },
})
</script>

<template>
    <v-select
        solo
        hide-details
        placeholder="Playthrough"
        prepend-inner-icon="mdi-book-open-page-variant-outline"
        :items="playthroughSteps"
        :value="value"
        @input="select($event)"
        clearable
    />
</template>

<style scoped>

</style>