import { render, staticRenderFns } from "./SpoiledHistory.vue?vue&type=template&id=2ba32480&scoped=true&"
import script from "./SpoiledHistory.vue?vue&type=script&lang=js&"
export * from "./SpoiledHistory.vue?vue&type=script&lang=js&"
import style0 from "./SpoiledHistory.vue?vue&type=style&index=0&id=2ba32480&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ba32480",
  null
  
)

export default component.exports